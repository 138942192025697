import { useRef, useEffect } from "react";
import Infocard from "../Components/Infocard";
import { Helmet } from "react-helmet";
import styles from "./styles/About.module.css"


// const geschichte = {
//     title: "Geschichte",
//     svg: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">{/*<!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->*/}<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/></svg>,
//     text: <><p>2014 startete Anna den Schulungsunterricht, nachdem sie die Pferdefachschule absolvierte.</p><p>Nun wird nur auf gut ausgebildeten Quarter Horses, aus österreichischer und italienischer Zucht Unterricht, in den Sparten Trail, Pleasure, Reining, Ranch Klassen und Horsemanship gegeben.</p></>
// }

// const anna = {
//     title: <>Anna</>,
//     image: "/pictures/site/anna.webp",
//     svg: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">{/*<!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->*/}<path d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM512 256c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM256 272c39.8 0 72-32.2 72-72s-32.2-72-72-72s-72 32.2-72 72s32.2 72 72 72z"/></svg>,
//     text: <p>Hallo, ich bin Anna.<br/>Pferdefacharbeiterin, Landwirtschaftliche Facharbeiterin, Westernreitwart, Instruktor und Wanderreitführerin. Ich habe das WRC, eine Englischreitausbildung bis Übungsleiterniveau, Bronzenes Fahrabzeichen<br/>   und bin Salzburger Landesmeisterin in Reining und Pleasure 2014 geworden.</p>
// }

// const oliver = {
//     title: <>Oliver<br/>A.</>,
//     image: "/pictures/site/oliver.webp",
//     svg: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">{/*<!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->*/}<path d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM512 256c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM256 272c39.8 0 72-32.2 72-72s-32.2-72-72-72s-72 32.2-72 72s32.2 72 72 72z"/></svg>,
//     text: <p>Hallo, ich bin Oliver.<br/>Ich bin staatlich geprüfter Wanderreitführer und begleite den Reitunterricht für unsere Rookies. Dazu helfe ich bei der Planung und Ausführung von Events und Kursen.</p>
// }

const hof = {
    title: <>Unsere<br/>Anlage</>,
    svg: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">{/*<!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zm0-160c-53 0-96-43-96-96s43-96 96-96s96 43 96 96s-43 96-96 96z"/></svg>,
    text: <><p>Willkommen auf unserer idyllischen Reitsport-Anlage, nur wenige Minuten von der Stadt Salzburg entfernt und bequem 10 Minuten von der Autobahn erreichbar. Hier erwartet Sie ein Paradies für Pferdeliebhaber, das keine Wünsche offenlässt.

    <br/><br/>Unsere weitläufige Anlage umfasst 13 Hektar wunderschöne Weideflächen, die Ihren Pferden viel Platz zum Grasen und Entspannen bieten. Der großzügige 30 × 50 Meter Sand-Reitplatz sowie die 23 × 47 Meter große Reithalle ermöglichen Ihnen und Ihrem Pferd, bei jedem Wetter optimal zu trainieren. Unsere modernen und hellen Einstellboxen bieten den idealen Rückzugsort für Ihre Pferde, damit sie sich rundum wohlfühlen.
    
    <br/><br/>Für die Erholung und Regeneration Ihrer Tiere steht Ihnen ein hochmodernes Pferdesolarium mit Infrarot-Wärmetherapie zur Verfügung, das eine wohltuende Entspannung nach dem Training bietet. Darüber hinaus lädt unser weitläufiges Ausreitwegenetz dazu ein, die wunderschöne Umgebung in vollen Zügen zu genießen.
    
    <br/><br/>Unser gemütliches Gasthaus sorgt in den kalten Monaten für wohlige Wärme und lädt zum Aufwärmen ein. An den warmen Sommertagen können unsere Gäste sich bei erfrischenden Getränken entspannen und die atemberaubende Aussicht auf die Natur genießen.
    
    <br/><br/>Egal, ob Sie sich auf intensives Training konzentrieren oder einfach nur die Ruhe und Schönheit der Natur genießen möchten – unsere Reitanlage bietet Ihnen alles, was das Herz eines jeden Pferdeliebhabers höherschlagen lässt.</p></>
}

const aboutUs = {
    title: <>Unsere<br/>Philosophie</>,
    svg: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">{/*<!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->*/}<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/></svg>,
    text: <><p>Willkommen auf der Cold Creek Ranch der American Riding Academy, einem Ort, an dem die Harmonie zwischen Mensch und Pferd an erster Stelle steht. Ob Sie sich auf den Weg zu sportlichen Erfolgen begeben, eine tiefe, vertrauensvolle Partnerschaft mit Ihrem Pferd aufbauen oder Ihre innere Stärke entfalten möchten – bei uns finden Sie die maßgeschneiderte Unterstützung, die Sie auf Ihrem individuellen Weg begleitet. Wir setzen auf respektvolle, ganzheitliche Ausbildung und fördern eine nachhaltige, harmonische Zusammenarbeit zwischen Ihnen und Ihrem Pferd oder gemeinsam mit unseren gut Ausgebildeten Quarter Horse Schulungspferden. „Wahre Stärke entsteht dort, wo Mensch und Pferd in Einklang gehen – im Vertrauen, in der Harmonie, im gemeinsamen Moment.“</p></>
}


function About() {

    const afterHeader = useRef(null);

    const handleScroll = () => {
      if (afterHeader.current) {
        afterHeader.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
        <Helmet>
            <title>Unsere Philosophie | Western Riding Acadamy</title>
            <meta name="description" content="Western Riding Acadamy - Über uns!" />
        </Helmet>
        <div className={styles.header}>
            <h1>Unsere Philosophie</h1>
            <img src="/pictures/site/about.webp" alt=""/>
            <svg xmlns="http://www.w3.org/2000/svg" className={styles.scrollDownSVG} viewBox="0 0 384 512"
                onClick={() => {
                    handleScroll();
                }}
            >{/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}<path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
        </div>
        <hr ref={afterHeader}/>
        <div className={styles.boxes}>
            {/* <Infocard info={anna} /> */}
            {/* <Infocard info={oliver} /> */}
            <Infocard info={aboutUs}/>
            <Infocard info={hof} />
        </div>
        </>
    );
}

export default About;
