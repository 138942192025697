import { Link } from "react-router-dom";
import styles from "./styles/NotFound.module.css"; // Import styles as a module
import { Helmet } from "react-helmet";
import { useEffect } from "react";

function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.notfoundContainer}>
      <Helmet>
        <title>404-Error | Western Riding Acadamy</title>
        <meta name="description" content="Western Riding Acadamy (Cold Creek Ranch) - Seite nicht gefunden!" />
      </Helmet>
      <div className={styles.notfoundContent}>
        <h1 className={styles.notfoundTitle}>404-Error</h1>
        <p className={styles.notfoundMessage}>
          Diese Seite existiert nicht, bitte klicken Sie unten, um zur Startseite zurückzukehren.
        </p>
        <Link to="/" className={styles.notfoundHomeButton}>
          Zur Startseite
        </Link>
      </div>
    </div>
  );
}

export default NotFound;