import { useEffect } from "react";
import { Helmet } from "react-helmet";
import styles from "./styles/Eventhub.module.css";
import Calender from "../Components/Calender";

function EventHub() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Event Hub | Western Riding Acadamy</title>
        <meta name="description" content="Western Riding Acadamy - Event Hub!" />
      </Helmet>
      <div className={styles.calenderContainer}>
        <Calender />
      </div>
    </>
  );
}

export default EventHub;
